import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PageHeader from "../../components/pageheader"
import Padding from "../../components/padding"
import styled from "styled-components"
import Layout from "../../components/layout"
import Stappen from "../../components/stappen"
import Stappen2 from "../../components/stappen2"
import SEO from "../../components/seo"
import { device } from "../../queries"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight,faDotCircle, faCheck } from "@fortawesome/free-solid-svg-icons"
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Img from "gatsby-image"


const Margin = styled.div`
  height: 60px;
`

const Styledcrumbs = styled(Breadcrumbs)`
    .MuiBreadcrumbs-separator{
        font-weight: 600;
    }
`
const Crumblink = styled(Link)`
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: var(--blue-primary);
    transition: all 0.25s cubic-bezier(.44,0,.17,1);
    &:hover{
        text-decoration: none;
        color: var(--blue-primary-h); 
    }
    &[aria-current="page"]{
        color: rgba(100,100,100);
        cursor: text;
        
        &:hover{
          

        }
    }
`

const Maincontent = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 60px;
`

const Paragraph = styled.p`
    padding: 10px 0;
`

const Itemlist = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 10px 0 0 0;
    li{
        font-weight: 400;
        width: 100%;
        padding: 5px 0;
        @media ${device.tablet}{
            width: 100%;
        }
    }
`

const StyledLiIcon = styled(FontAwesomeIcon)`
    color: var(--green-primary);
`

const Bigsub = styled.h2`
    font-weight: 700;
    font-size: 28px;
    color: var(--green-text);
`

const Subheader = styled.h2`
    font-weight: 700;
    font-size: 20px;
    color: var(--green-text);
`

const Containerpad = styled.div`
`
const Vertpad = styled.div`
    padding-bottom: 50px;
    @media ${device.laptopL}{
        padding: 0 20px 60px 20px;
    }

    @media ${device.mobileL}{
        padding: 0 15px 60px 15px;
    }
`


const Flexdivider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &>*{
        width: 48%;
        @media ${device.tablet}{
        width: 100%;
        &:last-of-type{
            padding-bottom: 0;
        }
        }

    }
    @media ${device.tablet}{
        flex-direction: column;
    }

`

const Checkgrid = styled.div`
    display: inline-grid;
    width: 100%;
    grid-template-columns: 50% 50%;
    gap: 15px 20px;
    padding: 10px 0;
    padding-top: 30px;
    @media ${device.tablet}{
        grid-template-columns: 100%;
    }


`
const Griditem = styled.div`
    display: flex;
    align-items: start;
    p{
        padding: 4px 10px;
    }

`

const Sbr = styled.br`

`

const Icoon = styled(FontAwesomeIcon)`
    font-size: 25px;
    display: inline-block;
    color: var(--green-primary);
`
function handleClick(event) {
    console.info("You clicked a breadcrumb.")
}

const Headerimage = ({something, className}) => {
    const data = useStaticQuery(graphql`
      query {
        placeholderImage: file(relativePath: { eq: "diensticonen/recycling.png" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        anotherImage: file(relativePath: { eq: "recycling/recycling.png" }) {
            childImageSharp {
              fluid(maxWidth: 2000 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        softdes: file(relativePath: { eq: "datavernietiging/softwarematige_dataverwijdering.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600 maxHeight: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mechdes: file(relativePath: { eq: "datavernietiging/mechanische_dataverwijdering.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600 maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    `)
    const image = [<Img className={className} fluid={data.placeholderImage.childImageSharp.fluid} />,<Img className={className} fluid={data.anotherImage.childImageSharp.fluid}/>, <Img className={className} fluid={data.softdes.childImageSharp.fluid} />, <Img className={className} fluid={data.mechdes.childImageSharp.fluid} />]
    return image[something];
    }


const Privacybeleid = () => (
    
  <Layout>
    <SEO title="Privacybeleid" />
    <PageHeader title="Privacybeleid">
        <Styledcrumbs aria-label="breadcrumb">
        <Crumblink href="/" onClick={handleClick}>
            Home
          </Crumblink>
          <Crumblink href="/informatie" onClick={handleClick}>
            Informatie
          </Crumblink>
          <Crumblink aria-current="page" href="/informatie/privacybeleid/" onClick={handleClick}>
            Privacybeleid
          </Crumblink>
        </Styledcrumbs>
    </PageHeader>
        <Maincontent>
            <Containerpad>
                <Vertpad>
                    
                </Vertpad>
                <Vertpad>
                    <Subheader>Functieomschrijving</Subheader>
                    <Paragraph>
                    Dit privacy beleid is van toepassing op alle persoonsgegevens die Green Digital heeft verkregen na initiatief van de betrokkenen die contact hebben (gehad) met Green Digital, zoals onze bezoekers, klanten en zakelijke contactpersonen<br/> 
                    Dagelijks verwelkomen wij veel bezoekers op onze website, die interesse hebben in onze producten.<br/> Om u als bezoeker optimaal te kunnen bedienen en begeleiden tijdens uw verblijf in onze website, maken wij gebruik van verschillende online ‘tools.
                    </Paragraph>
                    <Paragraph>
               
                    Uiteraard nemen wij uw privacy en online veiligheid hierbij uiterst serieus.<br/> De gegevens die u bij ons achterlaat, worden nooit aan derden verstrekt zonder dat wij daar toestemming voor vragen.<br/> We gaan zorgvuldig om met uw gegevens en doen er alles aan om u een veilig bezoek op Green Digital te bezorgen.<br/> Als u een bestelling bij ons plaatst, dan hebben wij uw gegevens nodig om ervoor te zorgen dat uw bestelling bij u bezorgd wordt.<br/> Wat wij met uw persoonlijke gegevens doen en waarom we ze nodig hebben, leggen wij hier voor u uit.
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Subheader>Gegevens</Subheader>
                    <Paragraph>
                    De persoonsgegevens die Green Digital verkrijgt zijn altijd verkregen na initiatief van de verstrekkende persoon of organisatie. <br/>De persoonsgegevens die Green Digital verwerkt zijn alle gegevens die herleidbaar zijn tot u als individu.<br/> Green Digital verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt. <br/>Green Digital verklaart de volgende gegevens te verwerken:
                    <Itemlist>
                                    <li><StyledLiIcon icon={faDotCircle} /> Naam</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Adres</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Postcode</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Woonplaats</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Emailadres</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Telefoonnummer</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> IP Adres</li>
                    </Itemlist>
                    
                    </Paragraph>
                    </Vertpad>
                    <Vertpad>
                    <Subheader>Doel</Subheader>
                    <Paragraph>

                        Green Digital verwerkt uw persoonsgegevens voor de volgende doelen:

                        <Itemlist>
                                    <li><StyledLiIcon icon={faDotCircle} /> U te informeren over de dienstverlening van Greendigital</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Het gebruikmaken van de website, waaronder het maken van afspraken</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Het beantwoorden van vragen die gesteld zijn via de website, telefonisch of per mail</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Het leveren van opslagmaterialen</li>
                                    <li><StyledLiIcon icon={faDotCircle} /> Het deugdelijk en betrouwbaar vernietigen van gegevens op datadragers</li>
                        </Itemlist>
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                <Subheader>Verstrekking van uw gegevens aan derden</Subheader>
                <Paragraph>
                    
                    Wij sluiten een zogenaamde verwerkersovereenkomst met partijen, waar wij gegevens aan verstrekken.<br/> Hierin staat dat zij net zo zorgvuldig met uw gegevens omgaan als wij.
                    <br/>Wij zullen uw gegevens zonder uw toestemming nooit aan derden verstrekken. 
                </Paragraph>
                </Vertpad>
                <Vertpad>
                <Subheader>Opslag en bewaartermijn</Subheader>
                <Paragraph>
                    
                Wij slaan uw gegevens binnen de Europese Economische Ruimte ("EER").<br/> Het kan echter voorkomen dat bepaalde gegevens die we verzamelen, worden doorgegeven aan of opgeslagen op een bestemming buiten de EER, bijvoorbeeld omdat een van onze externe dienstverleners daar gevestigd is.<br/> Wanneer hiervan sprake is, zorgen wij dat dit gebeurt op een veilige en rechtmatige manier.<br/> Op grond van de wet zijn wij verplicht uw gegevens minimaal 7 jaar te bewaren.
                </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Subheader> Beveiliging</Subheader>
                    <Paragraph>
                        Green Digital neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.<br/> Green Digital neemt verschillende maatregelen om uw persoonsgegevens te beveiligen.<br/> Zowel op organisatorisch als technisch vlak, onder meer via beveiligingssoftware zoals virusscanners, een SSL certificaat en een firewall.<br/> Binnen de webwinkel maken we gebruik van een beveiligde SSL-verbinding, te herkennen aan het groene slotje links bovenin de URL-balk. <br/>Dit betekent dat gegevens die u invult tijdens het bestelproces en in contactformulieren veilig en versleuteld worden verstuurd.
                    </Paragraph>
                </Vertpad>

                <Vertpad>
                    <Subheader> Rechten van betrokkenen</Subheader>
                    <Paragraph>
                    U kunt uw klantgegevens op verzoek inzien, aanpassen en laten verwijderen.<br/> Neemt u daartoe contact op via bovenstaande contactgegevens, of via info@greendigital.nl. <br/>De organisaties/personen waar Green Digital gegevens van gebruikt, kunnen bezwaar maken tegen het verwerken en opslaan van de gegevens.
                    </Paragraph>
                </Vertpad>
                <Vertpad>
                    <Subheader> Vragen?</Subheader>
                    <Paragraph>
                    Als u vragen heeft over deze privacyverklaring, neem dan contact met ons via: info@greendigital.nl
                    </Paragraph>
                </Vertpad>
                
            </Containerpad>

        </Maincontent>
    <Padding/>
  </Layout> 
)

export default Privacybeleid


